<template>
  <v-card elevation="1">
    <v-toolbar flat>
      <v-toolbar-title class="page-title">
        <v-icon class="secondary--text" left light>{{ icons.mdiMatrix }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-card class="mx-2" elevation="1">
          <v-card-text>
            <v-list dense>
              <v-subheader class="text-h6">Matrices</v-subheader>
              <v-list-item-group>
                <v-list-item v-for="(pricing, index) in pricings" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>{{ pricing.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon v-show="pricing.default">
                    <v-icon>{{ icons.mdiStar }}</v-icon>
                  </v-list-item-icon>
                  <v-menu offset-y bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn light icon v-bind="attrs" v-on="on">
                        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item @click.prevent="editPricingModal(pricing)">
                        <v-list-item-icon>
                          <v-icon color="primary" class="mx-1">{{ icons.mdiPencil }}</v-icon>
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-list-item @click.prevent="">
                        <v-list-item-icon>
                          <v-icon color="error" class="mx-1"> {{ icons.mdiClose }}</v-icon>
                          <v-list-item-title>Remove</v-list-item-title>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-layout class="ma-2">
            <div v-for="(permission, index) in permission" :key="index">
              <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                  <v-btn
                    v-show="user_submenu.create == 1 && permission.role_id == 3"
                    color="accent"
                    rounded
                    @click.prevent="addPricingModal"
                  >
                    <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                    <span>Create New </span>
                  </v-btn>
                </div>
              </div>
            </div>
            <div>
              <v-btn v-show="currentUser.role_id == 1" color="accent" rounded @click.prevent="addPricingModal">
                <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                <span>Create New </span>
              </v-btn>
            </div>
          </v-layout>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <v-card elevation="1" v-for="(pricing, index1) in pricingItems" :key="index1">
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th colspan="2" class="text-left">Cost</th>
                    <th class="text-left">Markup</th>
                    <th class="text-left">Margin</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(pricingItem, index2) in pricing.pricing_items" :key="index2">
                    <td width="150px">
                      <input type="hidden" readonly v-model="pricing.pricing_items[index2].pricing_id" />
                      <v-text-field
                        v-model="pricing.pricing_items[index2].cost_range1"
                        color="secondary"
                        label="Cost"
                        dense
                        outlined
                      ></v-text-field>
                    </td>

                    <td width="150px">
                      <v-text-field
                        v-model="pricing.pricing_items[index2].cost_range2"
                        color="secondary"
                        label="Cost"
                        dense
                        outlined
                      ></v-text-field>
                    </td>
                    <td width="150px">
                      <v-text-field
                        :append-icon="icons.mdiPercentOutline"
                        v-model="pricing.pricing_items[index2].markup"
                        color="secondary"
                        label="Markup"
                        dense
                        outlined
                      ></v-text-field>
                    </td>
                    <td width="150px">
                      <v-text-field
                        :append-icon="icons.mdiPercentOutline"
                        v-model="pricing.pricing_items[index2].margin"
                        color="secondary"
                        label="Margin"
                        dense
                        outlined
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div d-flex justify-space-between>
              <div v-for="(permission, index) in permission" :key="index">
                <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                  <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                    <v-btn
                      class="ma-2"
                      v-show="user_submenu.create == 1 && permission.role_id == 3"
                      @click.prevent="addRange(index1)"
                      outlined
                      color="primary"
                      ><v-icon class="mr-1">{{ icons.mdiPlus }}</v-icon
                      >Add Range
                    </v-btn>
                    <v-btn
                      @click.prevent="saveRange"
                      v-show="user_submenu.create == 1 && permission.role_id == 3"
                      class="ma-2"
                      outlined
                      color="primary"
                      ><v-icon class="mr-1">{{ icons.mdiContentSave }}</v-icon
                      >Save
                    </v-btn>
                  </div>
                </div>
              </div>
              <div>
                <v-btn
                  class="ma-2"
                  v-show="currentUser.role_id == 1"
                  @click.prevent="addRange(index1)"
                  outlined
                  color="primary"
                  ><v-icon class="mr-1">{{ icons.mdiPlus }}</v-icon
                  >Add Range
                </v-btn>
                <v-btn
                  @click.prevent="saveRange"
                  v-show="currentUser.role_id == 1"
                  class="ma-2"
                  outlined
                  color="primary"
                  ><v-icon class="mr-1">{{ icons.mdiContentSave }}</v-icon
                  >Save
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <addnew-pricing
      :dialog.sync="showNewPricingDialog"
      @open-dialog="showNewPricingDialog = true"
      @close-dialog="showNewPricingDialog = false"
    />
    <edit-pricing
      :dialog.sync="showEditPricingDialog"
      :pricing="pricing"
      @open-dialog="showEditPricingDialog = true"
      @close-dialog="showEditPricingDialog = false"
    />
  </v-card>
</template>
  
  <script>
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import {
  mdiAccountTie,
  mdiPlus,
  mdiLayersTripleOutline,
  mdiCar,
  mdiInformation,
  mdiContentSave,
  mdiClose,
  mdiMessageBulleted,
  mdiCheckboxMarkedOutline,
  mdiClipboardCheckOutline,
  mdiMatrix,
  mdiDotsVertical,
  mdiPencil,
  mdiStar,
  mdiPercentOutline,
  mdiDeleteForever,
} from '@mdi/js'

import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import { proxyRefs } from '@vue/composition-api'
import AddNewDialog from '@/views/settings/pricing/AddNewPricing.vue'
import EditDialog from '@/views/settings/pricing/EditPricing.vue'
export default {
  components: {
    'addnew-pricing': AddNewDialog,
    'edit-pricing': EditDialog,
  },
  created: function () {
    this.getAuthorizations()
    this.getPricings()
    this.getPricingById(1)
    window.mitt.on('loadPricing', () => {
      this.getPricings()
      this.getPricingById(1)
      this.getAuthorizations()
    })
  },
  data() {
    return {
      pricings: [],
      pricingItems: [
        {
          name: '',
          default: '',
          pricing_items: [
            {
              pricing_item_id: '',
              pricing_id: '',
              cost_range1: '',
              cost_range2: '',
              markup: '',
              margin: '',
            },
          ],
        },
      ],
      permission: {},
      pricing: {},
      icons: {
        mdiAccountTie,
        mdiPencil,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiLayersTripleOutline,
        mdiCar,
        mdiMessageBulleted,
        mdiCheckboxMarkedOutline,
        mdiClipboardCheckOutline,
        mdiMatrix,
        mdiDotsVertical,
        mdiStar,
        mdiPercentOutline,
      },
      showNewPricingDialog: false,
      showEditPricingDialog: false,
    }
  },
  methods: {
    getPricings: function () {
      this.$store
        .dispatch('pricing/fetchPricings')
        .then(response => {
          this.pricings = this.fetchPricings
        })
        .catch(err => {
          console.log(err)
        })
    },
    getAuthorizations: function () {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 9,
          submenu_id: 12,
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },

    getPricingById: function (id) {
      this.$store
        .dispatch('pricing/fetchPricingById', {
          pricing_id: id,
        })
        .then(response => {
          this.pricingItems = this.fetchPricingItems
        })
        .catch(err => {
          console.log(err)
        })
    },

    addRange: function (index1) {
      this.pricingItems[index1].pricing_items.push({
        pricing_item_id: '',
        pricing_id: '',
        cost_range1: '',
        cost_range2: '',
        markup: '',
        margin: '',
      })
    },

    saveRange: function () {
      this.$store
        .dispatch('pricing/storeNewPricingItem', {
          pricingItems: this.pricingItems,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record added successfully',
          })
          this.getPricingById(1)
        })
        .catch(err => {
          console.log(err)
        })
    },

    addPricingModal: function () {
      this.showNewPricingDialog = true
    },
    editPricingModal: function (pricing) {
      this.pricing = Object.assign({}, pricing)
      this.showEditPricingDialog = true
    },
  },

  computed: {
    ...mapGetters({
      fetchPricings: 'pricing/fetchPricings',
      fetchPricingItems: 'pricing/fetchPricingItems',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),
    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>
  <style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 8px 14px;
}
</style>